const restaurantData = JSON.parse(localStorage.getItem('restaurant'));
const restaurantId = restaurantData ? restaurantData.restaurantId : null;



const SidebarData = [
    {
        label: "Menu",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-home-variant-outline",
        url: "/dashboard",
        issubMenubadge: true,
        bgcolor: "bg-primary",
        badgeValue: "3",
    },
    {
        label: "Product Management",
        icon: "mdi mdi-calendar-outline",
        subItem: [
            { sublabel: "Table", link: `/pages-starter/${restaurantId}` },
            { sublabel: "Form", link: "/compose-email" },
        ]
    },
    {
        label: "Product Addons",
        icon: "mdi mdi-email-outline",
        subItem: [
            { sublabel: "Table", link: "/inbox" },
            { sublabel: "Form", link: "/read-email" },
        ],
    },
    {
        label: "Subcategories",
        icon: "mdi mdi-calendar-outline",
        subItem: [
            { sublabel: "Table", link: "/tables-listjs" },
            { sublabel: "Form", link: "/tables-basic" },
        ],
    },
    {
        label: "Orders",
        icon: "mdi mdi-truck-outline",
        subItem: [
            { sublabel: "ALL Orders", link: "/orders" },
            { sublabel: "Pending", link: "/ui-alerts" },
            { sublabel: "Cooking", link: "/ui-badge" },
            { sublabel: "Confirmed", link: "/ui-breadcrumb" },
            { sublabel: "Ready for delivery", link: "/ui-buttons" },
            { sublabel: "Food on the way", link: "/ui-cards" },
            { sublabel: "Refunded", link: "/ui-carousel" },
            { sublabel: "Delivered", link: "/ui-dropdowns" },
            { sublabel: "Refund requests", link: "/ui-images" },
        ],
    },
    {
        label: "Coupons",
        icon: "mdi mdi-calendar-outline",
        subItem: [
            { sublabel: "Table", link: "/table-datatables" },
            { sublabel: "Form", link: "/userprofile" },
        ],
    },
    {
        label: "Wallet & Withdraw",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        url: "/ui-rating",
    },
    {
        label: "Deals",
        icon: "mdi mdi-calendar-outline",
        subItem: [
            { sublabel: "Table", link: "/icons-materialdesign" },
            { sublabel: "Form", link: "/icon-boxicon" },
        ],
    },
    {
        label: "Order Report",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        url: "/icons-fontawesome",
    },
    {
        label: "Featured",
        icon: "mdi mdi-calendar-outline",
        isHasArrow: true,
        url: "/icon-dripicons",
    },
    {
        label: "Payments",
        icon: "ri-map-pin-line",
        subItem: [
            { sublabel: "Restaurant Fee", link: "/ui-popovers" },
            { sublabel: "Featured Fee", link: "/featured_payments" },
            { sublabel: "Order Payments", link: "/maps-google" },

        ],
    },
   
];

// Conditional logic to add "Authentication" only if restaurantId is not present
if (!restaurantId) {
    SidebarData.push({
        label: "Authentication",
        icon: "mdi mdi-account-circle-outline",
        subItem: [
            { sublabel: "Login", link: "/auth-login" },
            { sublabel: "Register", link: "/auth-register" },
        ],
    });
}


// const SidebarData = [
    // {
    //     label: "Menu",
    //     isMainMenu: true,
    // },
    // {
    //     label: "Dashboard",
    //     icon: "mdi mdi-home-variant-outline",
    //     url: "/dashboard",
    //     issubMenubadge: true,
    //     bgcolor: "bg-primary",
    //     badgeValue: "3",
        

    // },
   
    
    // {
    //     label: "Product Management",
    //     icon: "mdi mdi-calendar-outline",
    //     subItem: [
    //         { sublabel: "Table", link:`/pages-starter/${restaurantId}` },
    //         { sublabel: "Form", link: "/compose-email" },
    //     ]
    // },
    // {
    //     label: "Product Addons",
    //     icon: "mdi mdi-email-outline",
    //     subItem: [
    //         { sublabel: "Table", link: "/inbox" },
    //         { sublabel: "Form", link: "/read-email" },
    //         // { sublabel: "Email Compose", link: "/compose-email" },
    //     ],
    // },
   
    // // {
    // //     label: "Categories",
    // //     icon: "mdi mdi-calendar-outline",
    // //     isHasArrow: true,
    // //     url: "/calendar",
    // // },
    // {
    //     label: "Subcategories",
    //     icon: "mdi mdi-calendar-outline",
    //     subItem: [
    //         { sublabel: "Table", link: "/tables-listjs" },
    //         { sublabel: "Form", link: "/tables-basic" },
    //         // { sublabel: "Email Compose", link: "/compose-email" },
    //     ],
    // },
    // {
    //     label: "Coupons ",
    //     icon: "mdi mdi-calendar-outline",
    //     subItem: [
    //         { sublabel: "Table", link: "/table-datatables" },
    //         { sublabel: "Form", link: "/userprofile" },
    //         // { sublabel: "Email Compose", link: "/compose-email" },
    //     ],
       
    // },
    // {
    //     label: "Wallet & Withdraw ",
    //     icon: "mdi mdi-calendar-outline",
    //     isHasArrow: true,
    //     url: "/ui-rating",
    // },
    // {
    //     label: "Deals",
    //     icon: "mdi mdi-calendar-outline",
    //     subItem: [
    //         { sublabel: "Table", link: "/icons-materialdesign" },
    //         { sublabel: "Form", link: "/icon-boxicon" },
    //         // { sublabel: "Email Compose", link: "/compose-email" },
    //     ],
    // },
   
    // {
    //     label: "Order Report",
    //     icon: "mdi mdi-calendar-outline",
    //     isHasArrow: true,
    //     url: "/icons-fontawesome",
    // },
   
    // {
    //     label: "Featured",
    //     icon: "mdi mdi-calendar-outline",
    //     isHasArrow: true,
    //     url: "/icon-dripicons",
    // },
    // {
    //     label: "Payments",
    //     icon: "ri-map-pin-line",
    //     subItem: [
    //         // { sublabel: "Payments uploads", link: "/maps-google" },
    //         { sublabel: "Resturant Fee", link: "/ui-popovers" },
    //         { sublabel: "Featured Fee", link: "/featured_payments" },
    //     ],
    // },
    // {
    //     label: "Orders",
    //     icon: "mdi mdi-truck-outline",
    //     subItem: [
    //         { sublabel: "Pending", link: "/ui-alerts" },
    //         { sublabel: "Cooking", link: "/ui-badge" },
    //         { sublabel: "Confirmed", link: "/ui-breadcrumb" },
    //         { sublabel: "Ready for delivery", link: "/ui-buttons" },
    //         { sublabel: "Food on the way", link: "/ui-cards" },
    //         { sublabel: "Refunded", link: "/ui-carousel" },
    //         { sublabel: "Delivered", link: "/ui-dropdowns" },
    //         { sublabel: "Refund requests", link: "/ui-images" },

           
    //     ],
    // },

    // {
    //     label: "Pages",
    //     isMainMenu: true,
    // },

    // {
    //     label: "Utility",
    //     icon: "mdi mdi-format-page-break",
    //     subItem: [
    //         { sublabel: "Starter Page", link: "/pages-starter" },
    //         { sublabel: "Maintenance", link: "/pages-maintenance" },
    //         { sublabel: "Coming Soon", link: "/pages-comingsoon" },
    //         { sublabel: "Timeline", link: "/pages-timeline" },
    //         { sublabel: "FAQs", link: "/pages-faqs" },
    //         { sublabel: "Pricing", link: "/pages-pricing" },
    //         { sublabel: "Error 404", link: "/pages-404" },
    //         { sublabel: "Error 500", link: "/pages-500" },
    //     ],
    // },
    // {
    //     label: "Components",
    //     isMainMenu: true,
    // },
    // {
    //     label: "UI Elements",
    //     icon: "mdi mdi-briefcase-variant-outline",
    //     subItem: [
    //         { sublabel: "Alerts", link: "/ui-alerts" },
    //         { sublabel: "Badge", link: "/ui-badge" },
    //         { sublabel: "Breadcrumb", link: "/ui-breadcrumb" },
    //         { sublabel: "Buttons", link: "/ui-buttons" },
    //         { sublabel: "Cards", link: "/ui-cards" },
    //         { sublabel: "Carousel", link: "/ui-carousel" },
    //         { sublabel: "Dropdowns", link: "/ui-dropdowns" },
    //         { sublabel: "Grid", link: "/ui-grid" },
    //         { sublabel: "Images", link: "/ui-images" },
    //         { sublabel: "Lightbox", link: "/ui-lightbox" },
    //         { sublabel: "Modals", link: "/ui-modals" },
    //         { sublabel: "Offcanvas", link: "/ui-offcanvas" },
    //         { sublabel: "Range Slider", link: "/ui-rangeslider" },
    //         { sublabel: "Session Timeout", link: "/ui-sessiontimeout" },
    //         { sublabel: "Pagination", link: "/ui-pagination" },
    //         { sublabel: "Progress Bars", link: "/ui-progressbars" },
    //         { sublabel: "Placeholders", link: "/ui-placeholders" },
    //         { sublabel: "Tabs & Accordions", link: "/ui-tabs-accordions" },
    //         { sublabel: "Typography", link: "/ui-typography" },
    //         { sublabel: "Toasts", link: "/ui-toasts" },
    //         { sublabel: "Video", link: "/ui-video" },
    //         { sublabel: "Popovers & Tooltips", link: "/ui-popovers" },
    //         { sublabel: "Rating", link: "/ui-rating" },
    //     ],
    // },
    // {
    //     label: "Forms",
    //     icon: "ri-eraser-fill",
    //     issubMenubadge: true,
    //     bgcolor: "bg-danger",
    //     badgeValue: "8",
    //     subItem: [
    //         { sublabel: "Form Elements", link: "/form-elements" },
    //         { sublabel: "Form Validation", link: "/form-validation" },
    //         { sublabel: "Form Advanced Plugins", link: "/form-advanced" },
    //         { sublabel: "Form Editor", link: "/form-editor" },
    //         { sublabel: "Form File Upload", link: "/form-uploads" },
    //         { sublabel: "Form X-editable", link: "/form-editors" },
    //         { sublabel: "Form Wizard", link: "/form-wizard" },
    //         { sublabel: "Form Mask", link: "/form-mask" },
    //     ],
    // },
    // {
    //     label: "Tables",
    //     icon: "ri-table-2",
    //     subItem: [
    //         { sublabel: "Basic Tables", link: "/tables-basic" },
    //         { sublabel: "List Js", link: "/tables-listjs" },
    //         { sublabel: "React Datatables", link: "/table-datatables" },
    //     ],
    // },
    // {
    //     label: "Charts",
    //     icon: "ri-bar-chart-line",
    //     subItem: [
    //         { sublabel: "Apex Charts", link: "/chart-apexcharts" },
    //         { sublabel: "Chartjs Charts", link: "/chart-chartjscharts" },
    //         { sublabel: "Re Charts", link: "/chart-floatcharts" },
    //         { sublabel: "Knob Charts", link: "/chart-jknobcharts" },
    //         { sublabel: "Sparkline Charts", link: "/chart-sparklinecharts" },
    //     ],
    // },
    // {
    //     label: "Icons",
    //     icon: "ri-brush-line",
    //     subItem: [
    //         { sublabel: "Box Icons", link: "/icon-boxicon" },
    //         { sublabel: "Material Design", link: "/icons-materialdesign" },
    //         { sublabel: "Dripicons", link: "/icon-dripicons" },
    //         { sublabel: "Font Awesome", link: "/icons-fontawesome" },
    //     ],
    // },
    // {
    //     label: "Maps",
    //     icon: "ri-map-pin-line",
    //     subItem: [
    //         { sublabel: "Google Maps", link: "/maps-google" },
    //         { sublabel: "Vector Maps", link: "/maps-vector" },
    //     ],
    // },
    // {
    //     label: "Multi Level",
    //     icon: "ri-share-line",
    //     subItem: [
    //         { sublabel: "Level 1.1", link: "/#" },
    //         {
    //             sublabel: "Level 1.2", link: "/#",
    //             subMenu: [
    //                 { title: "Level 2.1" },
    //                 { title: "Level 2.2" },
    //             ],
    //         },
    //     ],
    // },
// ]
export default SidebarData;
