import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import axios from 'axios';

const DealsTable = () => {
    const [restaurantData, setRestaurantData] = useState(null);

    const restaurantId = JSON.parse(localStorage.getItem('restaurant'))?.restaurantId;

    useEffect(() => {
        const fetchRestaurantData = async () => {
            try {
                const response = await axios.get(`http://5.161.110.71:7000/common/restaurant/getRestaurant/${restaurantId}`);
                // Set the restaurant data directly since it's an object
                setRestaurantData(response);
            } catch (error) {
                console.error('Error fetching restaurant data:', error);
            }
        };

        if (restaurantId) {
            fetchRestaurantData();
        }
    }, [restaurantId]); // Only depend on restaurantId

    console.log(restaurantData);

    return (
        <React.Fragment>
            <Row style={{ marginTop: "100px", paddingLeft: "30px", paddingRight: "30px" }}>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Restaurant Details</h4>

                            {restaurantData ? (
                                <div className="table-responsive">
                                    <table className="table table-centered table-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">Deal Name</th>
                                                <th scope="col">Discount Start</th>
                                                <th scope="col">Discount</th>
                                                <th scope="col">Discount End</th>
                                                {/* <th scope="col">Status</th>
                                                <th scope="col">Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <h5 className="font-size-15 mb-0">{restaurantData.restaurantName}</h5>
                                                </td>
                                                <td>{restaurantData.discountStart}</td>
                                                <td className=''> &nbsp;&nbsp;&nbsp;&nbsp;{restaurantData.discountPercentage} %</td>
                                                <td>{restaurantData.discountEnd}</td>
                                                {/* <td>
                                                    <i className={`mdi mdi-checkbox-blank-circle me-1 ${restaurantData.status === 'Completed' ? 'text-success' : 'text-warning'}`}></i> {restaurantData.status}
                                                </td> */}
                                                {/* <td>
                                                    <button type="button" className="btn btn-outline-success btn-sm me-1">Edit</button>
                                                    <button type="button" className="btn btn-outline-danger btn-sm me-1">Cancel</button>
                                                </td> */}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <p>Loading...</p>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default DealsTable;
