import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Alert,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const FormValidations = () => {
  document.title = "Food Management | React Admin & Dashboard Template";

  const [restaurantId, setRestaurantId] = useState("");
  const [subcategories, setSubcategories] = useState([]);
  const [foodPic, setFoodPic] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const restaurant = localStorage.getItem("restaurant");
    if (restaurant) {
      try {
        const parsedRestaurant = JSON.parse(restaurant);
        setRestaurantId(parsedRestaurant.restaurantId);
        fetchSubcategories(parsedRestaurant.restaurantId);
      } catch (error) {
        console.error("Error parsing restaurant data:", error);
      }
    }
  }, []);

  const fetchSubcategories = async (restaurantId) => {
    try {
      const response = await axios.get(`http://5.161.110.71:7000/common/subCategory/getSubCategories/${restaurantId}`);
      setSubcategories(Array.isArray(response) ? response : []); // Ensure it is an array
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const handleUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("http://5.161.110.71:7000/common/auth/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data; 
    } catch (error) {
      console.error("Upload error:", error);
      throw new Error("File upload failed.");
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      subcategoryId: "",
      price: "",
      description: "",
      tagLine: "", 
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Food Name"),
      subcategoryId: Yup.string().required("Please Select a Subcategory"),
      price: Yup.number().required("Please Enter Price").min(0, "Price must be a positive number"),
      description: Yup.string().required("Please Enter Description"),
      tagLine: "", 
    }),
    onSubmit: async (values) => {
      setLoading(true);
      setMessage("");
      try {
        await axios.post("http://5.161.110.71:7000/common/restaurant", {
          ...values,
          restaurantId: restaurantId,
          foodPic: foodPic,
        });
        
        // Reset the form values
        validation.resetForm();
        setFoodPic(""); // Clear the foodPic state
        setMessage({ type: 'success', text: "Food item submitted successfully!" });

        // Clear success message after 5 seconds
        setTimeout(() => {
          setMessage("");
        }, 5000);
      } catch (error) {
        console.error("Error:", error);
        setMessage({ type: 'error', text: "Error submitting food item." });
      } finally {
        setLoading(false);
      }
    },
  });

  const handleFileChange = async (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      try {
        const uploadedUrl = await handleUpload(file);
        setFoodPic(uploadedUrl);
      } catch (error) {
        console.error("File upload failed:", error);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Foods" breadcrumbItem="Food Management" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4 className="card-title">Food Management Form</h4>
                  <p className="card-title-desc">
                    Use this form to manage food item information including name, subcategory, price, and description.
                  </p>

                  {message && (
                    <Alert color={message.type === 'success' ? 'success' : 'danger'}>
                      {message.text}
                    </Alert>
                  )}

                  <Form onSubmit={validation.handleSubmit}>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="name">Food Name</Label>
                          <Input
                            name="name"
                            placeholder="Food Name"
                            type="text"
                            id="name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name}
                            invalid={validation.touched.name && !!validation.errors.name}
                          />
                          {validation.touched.name && validation.errors.name && (
                            <FormFeedback>{validation.errors.name}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="subcategoryId">Subcategory</Label>
                          <Input
                            type="select"
                            name="subcategoryId"
                            id="subcategoryId"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.subcategoryId}
                            invalid={validation.touched.subcategoryId && !!validation.errors.subcategoryId}
                          >
                            <option value="">Select Subcategory</option>
                            {Array.isArray(subcategories) && subcategories?.map(subcat => (
                              <option key={subcat?._id} value={subcat?._id}>{subcat?.name}</option>
                            ))}
                          </Input>
                          {validation.touched.subcategoryId && validation.errors.subcategoryId && (
                            <FormFeedback>{validation.errors.subcategoryId}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="price">Price</Label>
                          <Input
                            name="price"
                            placeholder="Price"
                            type="number"
                            id="price"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.price}
                            invalid={validation.touched.price && !!validation.errors.price}
                          />
                          {validation.touched.price && validation.errors.price && (
                            <FormFeedback>{validation.errors.price}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="tagLine">Tag Line</Label>
                          <Input
                            name="tagLine"
                            placeholder="Short Tag Line"
                            type="text"
                            id="tagLine"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.tagLine}
                            invalid={validation.touched.tagLine && !!validation.errors.tagLine}
                          />
                          {validation.touched.tagLine && validation.errors.tagLine && (
                            <FormFeedback>{validation.errors.tagLine}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="description">Description</Label>
                          <Input
                            name="description"
                            placeholder="Food Description"
                            type="textarea"
                            id="description"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description}
                            invalid={validation.touched.description && !!validation.errors.description}
                          />
                          {validation.touched.description && validation.errors.description && (
                            <FormFeedback>{validation.errors.description}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="foodPic">Upload Food Image</Label>
                          <Input
                            type="file"
                            id="foodPic"
                            onChange={handleFileChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="primary" type="submit" disabled={loading}>
                      {loading ? "Submitting..." : "Submit"}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FormValidations;
