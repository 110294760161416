import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Alert,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const SubcategoryForm = () => {
  document.title = "Subcategory Form | Upzet - React Admin & Dashboard Template";

  const [restaurantId, setRestaurantId] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const restaurant = JSON.parse(localStorage.getItem("restaurant"));
    if (restaurant) {
      setRestaurantId(restaurant?.restaurantId); // Assuming restaurantId is stored as a string
    }
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      subcategoryName: "",
    },
    validationSchema: Yup.object({
      subcategoryName: Yup.string().required("Subcategory Name is required"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await axios.post("http://5.161.110.71:7000/common/subCategory/createSubCategories", {
          name: values.subcategoryName,
          restaurantId: restaurantId, // Include restaurantId in the payload
        });
        console.log("Success:", response);
        
        // Set success message
        setSuccessMessage("Subcategory uploaded successfully!");

        // Clear the form
        validation.resetForm();

        // Clear success message after 5 seconds
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
      } catch (error) {
        console.error("Error:", error);
        // Handle error accordingly
      }
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Subcategory Form" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4 className="card-title">Subcategory Form</h4>
                  <p className="card-title-desc">
                    Provide the name for the subcategory.
                  </p>

                  {successMessage && <Alert color="success">{successMessage}</Alert>} {/* Success message */}

                  <Form onSubmit={validation.handleSubmit}>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="subcategoryName">Subcategory Name</Label>
                          <Input
                            name="subcategoryName"
                            placeholder="Enter Subcategory Name"
                            type="text"
                            id="subcategoryName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.subcategoryName}
                            invalid={validation.touched.subcategoryName && validation.errors.subcategoryName ? true : false}
                          />
                          {validation.touched.subcategoryName && validation.errors.subcategoryName ? (
                            <FormFeedback>{validation.errors.subcategoryName}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SubcategoryForm;
