import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Spinner } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import logolight from '../../assets/images/logo-light.png';
import logodark from '../../assets/images/logo-dark.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const Register = () => {
    const [formData, setFormData] = useState({
        ownerName: '',
        restaurantName: '',
        restaurantPhoneNumber: '',
        email: '',
        password: '',
        accountNumber: '',
        accountTitle: '',
        accountBank: '',
        contractFile: null,
        restaurantLogo: null,
        category: '',
        restaurantAddress: '',
        restaurantLongitude: '',
        restaurantLatitude: '',
        restaurantCity: '',
    });

    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();
    const [isUploadingLogo, setIsUploadingLogo] = useState(false);
    const [isUploadingContract, setIsUploadingContract] = useState(false);
    const [markerPosition, setMarkerPosition] = useState({ lat: 71, lng: 31 });
    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
    const googleMapsApiKey = "AIzaSyDJs2w7V3KzUjb5ekDkd9AWBqwbUgWq2yk"; // Your API Key

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get('http://5.161.110.71:7000/common/category/getAllCategories');
                setCategories(response || []);
            } catch (error) {
                console.error('Error fetching categories:', error);
                toast.error('Failed to load categories.');
            }
        };

        const getLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { longitude, latitude } = position.coords;
                        setMarkerPosition({ lat: latitude, lng: longitude });
                        setMapCenter({ lat: latitude, lng: longitude });
                        setFormData((prevData) => ({
                            ...prevData,
                            restaurantLongitude: longitude,
                            restaurantLatitude: latitude,
                        }));
                        fetchAddressFromLatLng(latitude, longitude);
                    },
                    (error) => {
                        console.error('Error getting location:', error);
                        toast.error('Could not retrieve your location. Please check your location settings.');
                        const defaultLocation = { lat: 40.712776, lng: -74.005974 }; // New York City
                        setMarkerPosition(defaultLocation);
                        setMapCenter(defaultLocation);
                        fetchAddressFromLatLng(defaultLocation.lat, defaultLocation.lng);
                    }
                );
            } else {
                console.log('Geolocation is not supported by this browser.');
                const defaultLocation = { lat: 40.712776, lng: -74.005974 }; // New York City
                setMarkerPosition(defaultLocation);
                setMapCenter(defaultLocation);
                fetchAddressFromLatLng(defaultLocation.lat, defaultLocation.lng);
            }
        };

        fetchCategories();
        getLocation();
    }, []);

    const fetchAddressFromLatLng = async (lat, lng) => {
        try {
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${googleMapsApiKey}`);
            if (response?.results?.length > 0) {
                const address = response.results[0].formatted_address;
                setFormData((prevData) => ({
                    ...prevData,
                    restaurantAddress: address,
                }));
            } else {
                toast.error('Could not retrieve address for this location.');
            }
        } catch (error) {
            console.error('Geocoding API error:', error);
            toast.error('Could not retrieve address for this location.');
        }
    };

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === 'file') {
            setFormData((prevData) => ({ ...prevData, [name]: files[0] }));
        } else {
            setFormData((prevData) => ({ ...prevData, [name]: value }));
        }
    };

    const handleUpload = async (file, setUploading) => {
        const formData = new FormData();
        formData.append('file', file);

        setUploading(true);
        try {
            const response = await axios.post('http://5.161.110.71:7000/common/auth/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Upload error:', error);
            toast.error('File upload failed. Please try again.');
            throw new Error('File upload failed.');
        } finally {
            setUploading(false);
        }
    };

    const validateForm = () => {
        const newErrors = {};
        for (const [key, value] of Object.entries(formData)) {
            if (!value && key !== 'contractFile' && key !== 'restaurantLogo') {
                newErrors[key] = `${key} is required`;
            }
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const expectedFileName = "food dashboards.docx"; // Expected contract file name

    const handleContractFileUpload = (file) => {
        if (file && file.name !== expectedFileName) {
            toast.error(`Please upload the file named ${expectedFileName}.`);
            return false;
        }
        return true;
    };

    const handleMapClick = async (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setMarkerPosition({ lat, lng });
        setFormData((prevData) => ({
            ...prevData,
            restaurantLongitude: lng,
            restaurantLatitude: lat,
        }));

        await fetchAddressFromLatLng(lat, lng);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setIsLoading(true);
            try {
                if (formData.contractFile && !handleContractFileUpload(formData.contractFile)) {
                    setIsLoading(false);
                    return;
                }

                const logoUrl = formData.restaurantLogo ? await handleUpload(formData.restaurantLogo, setIsUploadingLogo) : null;
                const contractUrl = formData.contractFile ? await handleUpload(formData.contractFile, setIsUploadingContract) : null;

                const dataToSubmit = {
                    ...formData,
                    restaurantLogo: logoUrl,
                    contractFile: contractUrl,
                };

                await axios.post('http://5.161.110.71:7000/common/restaurant/createRestaurant', dataToSubmit, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                toast.success('Registration successful!');
                setFormData({
                    ownerName: '',
                    restaurantName: '',
                    restaurantPhoneNumber: '',
                    email: '',
                    password: '',
                    accountNumber: '',
                    accountTitle: '',
                    accountBank: '',
                    restaurantLogo: null,
                    contractFile: null,
                    category: '',
                    restaurantAddress: '',
                    restaurantCity: '',
                    restaurantLongitude: '',
                    restaurantLatitude: '',
                });
                navigate(`/auth-login`);
            } catch (error) {
                console.error('Error submitting form:', error);
                toast.error('Registration failed. Please try again.');
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div className="bg-pattern">
            <div className="bg-overlay"></div>
            <div className="account-pages pt-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={6} md={8} xl={8}>
                            <Card className='mt-5'>
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <Link to="/">
                                            <img src={logodark} alt="" height="24" className="auth-logo logo-dark mx-auto" />
                                            <img src={logolight} alt="" height="24" className="auth-logo logo-light mx-auto" />
                                        </Link>
                                    </div>

                                    <h4 className="font-size-18 text-muted text-center mt-2">Free Register</h4>
                                    <p className="text-muted text-center mb-4">Get your free Upzet account now.</p>
                                    <ToastContainer />
                                    <form className="form-horizontal" onSubmit={handleSubmit}>
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-4">
                                                    <label className="form-label" htmlFor="ownerName">Owner Name</label>
                                                    <input type="text" className="form-control" id="ownerName" name="ownerName" placeholder="Enter owner name" onChange={handleChange} />
                                                    {errors.ownerName && <small className="text-danger">{errors.ownerName}</small>}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-4">
                                                    <label className="form-label" htmlFor="restaurantName">Restaurant Name</label>
                                                    <input type="text" className="form-control" id="restaurantName" name="restaurantName" placeholder="Enter restaurant name" onChange={handleChange} />
                                                    {errors.restaurantName && <small className="text-danger">{errors.restaurantName}</small>}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-4">
                                                    <label className="form-label" htmlFor="restaurantPhoneNumber">Phone Number</label>
                                                    <input type="text" className="form-control" id="restaurantPhoneNumber" name="restaurantPhoneNumber" placeholder="Enter phone number" onChange={handleChange} />
                                                    {errors.restaurantPhoneNumber && <small className="text-danger">{errors.restaurantPhoneNumber}</small>}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-4">
                                                    <label className="form-label" htmlFor="email">Email</label>
                                                    <input type="email" className="form-control" id="email" name="email" placeholder="Enter email" onChange={handleChange} />
                                                    {errors.email && <small className="text-danger">{errors.email}</small>}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-4">
                                                    <label className="form-label" htmlFor="password">Password</label>
                                                    <input type="password" className="form-control" id="password" name="password" placeholder="Enter password" onChange={handleChange} />
                                                    {errors.password && <small className="text-danger">{errors.password}</small>}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-4">
                                                    <label className="form-label" htmlFor="accountNumber">Account Number</label>
                                                    <input type="text" className="form-control" id="accountNumber" name="accountNumber" placeholder="Enter account number" onChange={handleChange} />
                                                    {errors.accountNumber && <small className="text-danger">{errors.accountNumber}</small>}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-4">
                                                    <label className="form-label" htmlFor="accountTitle">Account Title</label>
                                                    <input type="text" className="form-control" id="accountTitle" name="accountTitle" placeholder="Enter account title" onChange={handleChange} />
                                                    {errors.accountTitle && <small className="text-danger">{errors.accountTitle}</small>}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-4">
                                                    <label className="form-label" htmlFor="accountBank">Bank Name</label>
                                                    <input type="text" className="form-control" id="accountBank" name="accountBank" placeholder="Enter bank name" onChange={handleChange} />
                                                    {errors.accountBank && <small className="text-danger">{errors.accountBank}</small>}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-4">
                                                    <label className="form-label" htmlFor="restaurantLongitude">Longitude</label>
                                                    <input type="text" className="form-control" id="restaurantLongitude" name="restaurantLongitude" value={formData.restaurantLongitude} readOnly />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-4">
                                                    <label className="form-label" htmlFor="restaurantLatitude">Latitude</label>
                                                    <input type="text" className="form-control" id="restaurantLatitude" name="restaurantLatitude" value={formData.restaurantLatitude} readOnly />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <div className="mb-4">
                                                    <label className="form-label" htmlFor="restaurantLogo">Restaurant Logo</label>
                                                    <input type="file" className="form-control" id="restaurantLogo" name="restaurantLogo" accept=".jpg,.jpeg,.png" onChange={handleChange} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-4">
                                                    <label className="form-label" htmlFor="contractFile">Contract File (Upload: {expectedFileName})</label>
                                                    <input type="file" className="form-control" id="contractFile" name="contractFile" accept=".docx" onChange={handleChange} />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                            <div className="text-center mt-3">
                                            <a href={`/${expectedFileName}`} className="btn btn-success" download>Download Contract</a>
                                        </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <div className="mb-4">
                                                    <label className="form-label" htmlFor="category">Category</label>
                                                    <select className="form-control" id="category" name="category" onChange={handleChange}>
                                                        <option value="">Select category</option>
                                                        {categories?.map((category) => (
                                                            <option key={category?._id} value={category._id}>{category.name}</option>
                                                        ))}
                                                    </select>
                                                    {errors.category && <small className="text-danger">{errors.category}</small>}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <div className="mb-4">
                                                    <label className="form-label" htmlFor="restaurantAddress">Restaurant Address</label>
                                                    <input type="text" className="form-control" id="restaurantAddress" name="restaurantAddress" value={formData.restaurantAddress} readOnly />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                        <LoadScript googleMapsApiKey={googleMapsApiKey}>
                <GoogleMap
                    mapContainerStyle={{ height: "400px", width: "100%" }}
                    center={mapCenter}
                    zoom={10}
                    onClick={handleMapClick}
                >
                    <Marker position={markerPosition} />
                </GoogleMap>
            </LoadScript>
                                        </Row>
                                      
                                        <div className="text-center mt-4">
                                            <button type="submit" className="btn btn-primary w-100" disabled={isLoading}>
                                                {isLoading ? <Spinner size="sm" /> : 'Register'}
                                            </button>
                                        </div> 
                                        <div className="text-center mt-2">
                                        <p className="text-muted">Already have an account? <Link to="/auth-login" className="text-primary">Login</Link></p>
                                    </div>
                                     
                                        
                                    </form>

                                  
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
          
        </div>
    );
};

export default Register;
