import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Row, Col } from 'reactstrap';

const Payments_featured = () => {
    const [transactionData, setTransactionData] = useState([]);
    const restaurantId = JSON.parse(localStorage.getItem('restaurant'))?.restaurantId; // Get the restaurant ID from local storage
    console.log(restaurantId)
    useEffect(() => {
        const fetchRestaurantFees = async () => {
            try {
                const response = await axios.get(`http://5.161.110.71:7000/common/featuredBought/restaurant/${restaurantId}`);
                setTransactionData(response);
                console.log(response) // Assuming response.data is an array of transactions
            } catch (error) {
                console.error('Failed to fetch restaurant fees:', error);
            }
        };

        if (restaurantId) {
            fetchRestaurantFees();
        }
    }, [restaurantId]);

    return (
        <React.Fragment>
            <Row style={{ marginTop: "100px", paddingLeft: "30px", paddingRight: "30px" }}>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Featured Fees</h4>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            
                                            <th scope="col">Transaction Id</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Status</th>
                                            {/* <th scope="col">Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transactionData?.map((item, key) => (
                                            <tr key={key}>
                            
                                                
                                                <td>{item?.transactionId}</td>
                                                <td>{item?.createdAt}</td>
                                                <td>{item?.paymentAmount}$</td>
                                                <td>
                                                    <i className={`mdi mdi-checkbox-blank-circle me-1 text-${item.status ? 'success' : 'danger'}`}></i> {item.status ==true ?'True':'False'}
                                                </td>
                                                {/* <td>
                                                    <button type="button" className="btn btn-outline-success btn-sm me-1">Edit</button>
                                                    <button type="button" className="btn btn-outline-danger btn-sm me-1">Cancel</button>
                                                </td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default Payments_featured;
