import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useParams } from 'react-router-dom';

// Define the validation schema using Yup
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .trim(),
  type: Yup.string()
    .required('Type is required')
    .trim(),
  price: Yup.number()
    .required('Price is required')
    .positive('Price must be a positive number')
    .min(0, 'Price cannot be negative'),
});

const UpdateAddAddonForm = () => {
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const restaurantId = JSON.parse(localStorage.getItem('restaurant'))?.restaurantId;
  const { id } = useParams();

  const formik = useFormik({
    initialValues: {
      name: '',
      type: '',
      price: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const addonData = {
        ...values,
        restaurantId,
      };

      setLoading(true);

      try {
        await axios.put(`http://5.161.110.71:7000/common/adson/${id}`, addonData);
        setSuccessMessage('Add-on updated successfully!'); // Set success message

        // Auto-dismiss the message after 5 seconds
        setTimeout(() => {
          setSuccessMessage('');
        }, 5000);

        formik.resetForm();
      } catch (error) {
        console.error('Error updating add-on:', error);
        setSuccessMessage('Failed to update add-on. Please try again.');

        // Auto-dismiss the error message after 5 seconds
        setTimeout(() => {
          setSuccessMessage('');
        }, 5000);
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const fetchAddOnData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`http://5.161.110.71:7000/common/adson/single/${id}`);
        const { name, type, price } = response; // Assuming the API returns the add-on object

        // Update formik with fetched values
        formik.setValues({
          name,
          type,
          price,
        });
      } catch (error) {
        console.error("Error fetching add-on data:", error);
        // Handle error accordingly
      } finally {
        setLoading(false);
      }
    };

    fetchAddOnData();
  }, [id]);

  return (
    <Container className="mt-4">
      <Breadcrumbs title="Forms" breadcrumbItem="Food Add-On Management" />
      <Card>
        <CardBody>
          <h4 className="card-title">Food Add-On Management</h4>
          <p className="card-title-desc">
            Manage your food add-ons with detailed options and validations.
          </p>

          {successMessage && <div className="alert alert-info">{successMessage}</div>} {/* Success message */}

          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label for="name">Name:</Label>
              <Input
                type="text"
                name="name"
                id="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                invalid={formik.touched.name && Boolean(formik.errors.name)}
              />
              <FormFeedback>{formik.errors.name}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="type">Type:</Label>
              <Input
                type="text"
                name="type"
                id="type"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.type}
                invalid={formik.touched.type && Boolean(formik.errors.type)}
              />
              <FormFeedback>{formik.errors.type}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="price">Price:</Label>
              <Input
                type="number"
                name="price"
                id="price"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.price}
                invalid={formik.touched.price && Boolean(formik.errors.price)}
              />
              <FormFeedback>{formik.errors.price}</FormFeedback>
            </FormGroup>
            <Button type="submit" color="primary" disabled={loading}>
              {loading ? 'Updating...' : 'Update Add-on'}
            </Button>
            {loading && <p>Loading...</p>}
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
};

export default UpdateAddAddonForm;
