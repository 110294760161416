import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const ProductAddonsTable = () => {
  const [addons, setAddons] = useState([]);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [modal, setModal] = useState(false);
  const [selectedAddonId, setSelectedAddonId] = useState(null);
  const restaurantId = JSON.parse(localStorage.getItem('restaurant'))?.restaurantId;
 const navigate =useNavigate()
  useEffect(() => {
    const getAddons = async () => {
      try {
        const response = await axios.get(`http://5.161.110.71:7000/common/adson/${restaurantId}`);
        setAddons(response); // Assuming the data is in response.data
      } catch (error) {
        console.error('Error fetching add-ons:', error);
      }
    };

    if (restaurantId) {
      getAddons();
    }
  }, [restaurantId]);

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`http://5.161.110.71:7000/common/adson/${selectedAddonId}`);
      setAddons(addons.filter(addon => addon._id !== selectedAddonId)); // Remove deleted addon from state
      setDeleteMessage('Addon deleted successfully!'); // Set the delete message

      // Clear the message after 5 seconds
      setTimeout(() => {
        setDeleteMessage('');
      }, 5000);
    } catch (error) {
      console.error('Error deleting add-on:', error);
      setDeleteMessage('Failed to delete add-on'); // Set the error message

      // Clear the message after 5 seconds
      setTimeout(() => {
        setDeleteMessage('');
      }, 5000);
    } finally {
      toggleModal(); // Close the modal
    }
  };

  const confirmDelete = (id) => {
    setSelectedAddonId(id);
    toggleModal(); // Show modal
  };

  return (
    <React.Fragment>
      <Row style={{ marginTop: "100px", paddingLeft: "30px", paddingRight: "30px" }}>
        <Col lg={12}>
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-4">Product Addons</h4>

              {deleteMessage && <div className="alert alert-info">{deleteMessage}</div>} {/* Delete message */}

              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: "50px" }}>
                        <div className="form-check">
                          <label className="form-check-label" htmlFor="customCheckall"></label>
                        </div>
                      </th>
                      <th scope="col" style={{ width: "60px" }}></th>
                      <th scope="col">Addon Name</th>
                      <th scope="col">Price</th>
                      <th scope="col">Type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {addons?.map((item) => (
                      <tr key={item._id}>
                        <td>
                          <div className="form-check">
                            <input type="checkbox" className="form-check-input" id={`checkbox-${item._id}`} />
                            <label className="form-check-label" htmlFor={`checkbox-${item._id}`}></label>
                          </div>
                        </td>
                        <td>
                          <div className="avatar-xs">
                            <span className="avatar-title rounded-circle bg-primary-subtle text-success">
                              {item.name.charAt(0)}
                            </span>
                          </div>
                        </td>
                        <td>
                          <h5 className="font-size-15 mb-0">{item.name}</h5>
                        </td>
                        <td>$ {item.price?.toFixed(2)}</td>
                        <td>{item.type}</td>
                        <td>
                          <i className={"mdi mdi-checkbox-blank-circle me-1 text-" + (item.status ? 'success' : 'danger')}></i>
                          {item.status ? 'True' : 'False'}
                        </td>
                        <td>
                          <button type="button" className="btn btn-outline-success btn-sm me-1" onClick={()=>{navigate(`/update_addon/${item?._id}`)}}>Edit</button>
                          <button 
                            type="button" 
                            className="btn btn-outline-danger btn-sm me-1"
                            onClick={() => confirmDelete(item._id)} // Pass the item's id to confirm delete
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Confirmation Modal */}
          <Modal isOpen={modal} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Confirm Deletion</ModalHeader>
            <ModalBody>
              Are you sure you want to delete this add-on?
            </ModalBody>
            <ModalFooter>
              <button className="btn btn-secondary" onClick={toggleModal}>Cancel</button>
              <button className="btn btn-danger" onClick={handleDelete}>OK</button>
            </ModalFooter>
          </Modal>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default ProductAddonsTable;
