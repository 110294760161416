import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import logolight from "../../assets/images/logo-light.png";
import logodark from "../../assets/images/logo-dark.png";
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.className = "bg-pattern";
    return () => {
      document.body.className = "";
    };
  }, []);

  const validation = useFormik({
    initialValues: {
      restaurantPhoneNumber: '',
      password: '',
    },
    validationSchema: Yup.object({
      restaurantPhoneNumber: Yup.string().required("Please Enter Your Phone Number"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async (values) => {
      setLoading(true); // Show loader
      try {
        const response = await axios.post('http://5.161.110.71:7000/common/restaurant/login', {
          restaurantPhoneNumber: values.restaurantPhoneNumber,
          password: values.password,
        });

        // Check if the response is successful and paymentStatus
        if (response.message === 'Login successful') {
          // if (response.paymentStatus !== false) {
          //   toast.error("Login failed. Payment status is inactive."); // Show error message
          //   return; // Prevent further execution
          // }
          // if (response.status !== false) {
          //   toast.error("Login failed. Your account status is inactive."); // Show error message
          //   return; // Prevent further execution
          // }

          toast.success("Login successful!"); // Show success toast
          localStorage.setItem('restaurant', JSON.stringify(response));
          setTimeout(() => {
            navigate(`/pages-starter/${response.restaurantId}`);
          }, 2000);
        } else {
          toast.error("Login failed. Please check your credentials.");
        }

      } catch (error) {
        // Check for specific error message from the API
        if (error.response && error.response.data.message === 'Sorry! the data you are looking for could not be found') {
          toast.error("Login failed. Please check your credentials."); 
        } else {
          toast.error("An error occurred. Please try again."); // Handle other errors
        }
      } finally {
        setLoading(false); // Hide loader
      }
    }
  });

  const backgroundStyle = {
    backgroundImage: "url('/path/to/your/background-image.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div style={backgroundStyle}>
        <div className="bg-overlay"></div>
        <div className="account-pages my-5 pt-5">
          <Container>
            <Row className="justify-content-center">
              <Col lg={6} md={8} xl={4}>
                <Card>
                  <CardBody className="p-4">
                    <div className="text-center">
                      <Link to="/">
                        <img src={logodark} alt="" height="24" className="auth-logo logo-dark mx-auto" />
                        <img src={logolight} alt="" height="24" className="auth-logo logo-light mx-auto" />
                      </Link>
                    </div>
                    <h4 className="font-size-18 text-muted mt-2 text-center">Welcome Back!</h4>
                    <p className="mb-5 text-center">Sign in to continue.</p>
                    <Form onSubmit={validation.handleSubmit}>
                      {validation.errors.global && <Alert color="danger"><div>{validation.errors.global}</div></Alert>}
                      <Row>
                        <Col md={12}>
                          <div className="mb-4">
                            <Label className="form-label">Phone Number</Label>
                            <Input
                              name="restaurantPhoneNumber"
                              className="form-control"
                              placeholder="Enter Phone Number"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.restaurantPhoneNumber}
                              invalid={validation.touched.restaurantPhoneNumber && validation.errors.restaurantPhoneNumber ? true : false}
                            />
                            {validation.touched.restaurantPhoneNumber && validation.errors.restaurantPhoneNumber ? (
                              <FormFeedback type="invalid"><div>{validation.errors.restaurantPhoneNumber}</div></FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              type="password"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password}
                              invalid={validation.touched.password && validation.errors.password ? true : false}
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid"><div>{validation.errors.password}</div></FormFeedback>
                            ) : null}
                          </div>
                          <div className="d-grid mt-4">
                            <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading}>
                              {loading ? "Loading..." : "Log In"}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p className="text-white-50">Don't have an account? <Link to="/auth-register" className="fw-medium text-primary">Register</Link></p>
                  <p className="text-white-50">© {new Date().getFullYear()} Your App Name. Crafted with <i className="mdi mdi-heart text-danger"></i> by Your Company</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
